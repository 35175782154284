import React, { useEffect, useState } from "react";

//imagen de fondo
//import image_bg from '../../assets/images/bg.png'
import logo from "../../assets/images/logo.png";
import image_bg from "../../assets/images/image_bg.jpg";

//icons
import home from "../../assets/icons/home2.png";
import business from "../../assets/icons/tienda.svg";
import medium from "../../assets/icons/medium.svg";

//constantes
import * as constant from "../../constants/Global";
import axios from "axios";

//load
import Spinner from "../../components/Loader/load";

const Home = (props) => {
  const [header, setHeader] = useState();
  const [sectionHome, setSectionHome] = useState();
  const [sectionBussines, setSectionBussines] = useState();
  const [load, setLoad] = useState(false);

  var sectionStyle = {
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    width: "100wv",
    height: "100vh",
    backgroundImage: `url(${constant.URLIMG}${
      window.innerWidth > 740 ? "web" : "movil"
    }/landingpage/3.jpg)`,
  };

  console.log("window.innerWidthf", window.innerWidth);

  /* const redirectWapp = () => {
        window.location.href = "https://api.whatsapp.com/send?phone=+573152003046&text=Hola!%20Buen%20Dia"
    } */

  const getHeader = () => {
    setLoad(true);
    axios
      .get(constant.ENDPOINT + "landing/configuracion?type=3", {
        headers: {
          "Ocp-Apim-Subscription-Key": constant.SUBCRIPTIONKEY,
        },
      })
      .then((resp) => {
        setHeader(resp.data);
        getHome();
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  const getHome = () => {
    axios
      .get(constant.ENDPOINT + "landing/configuracion?type=1", {
        headers: {
          "Ocp-Apim-Subscription-Key": constant.SUBCRIPTIONKEY,
        },
      })
      .then((resp) => {
        setSectionHome(resp.data);
        getBussines();
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  const getBussines = () => {
    axios
      .get(constant.ENDPOINT + "landing/configuracion?type=2", {
        headers: {
          "Ocp-Apim-Subscription-Key": constant.SUBCRIPTIONKEY,
        },
      })
      .then((resp) => {
        setSectionBussines(resp.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  const redirect = (host) => {
    window.location.href = host;
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#eee";
    getHeader();
  }, []);

  return (
    <React.Fragment>
      {load ? (
        <Spinner />
      ) : (
        <div className="landing-page" style={sectionStyle}>
          <div className="logo">
            <img src={logo}></img>
          </div>
          <div className="head">
            <div className="head-title">
              {header ? (
                <React.Fragment>
                  <h2>{header.titulo}</h2>
                  <h1>{header.descripcion1}</h1>
                  <h3>{header.descripcion2}</h3>
                </React.Fragment>
              ) : null}
            </div>
          </div>
          <div className="section">
            <div
              className="home"
              onClick={(e) => redirect(constant.HOMEHEADER)}
            >
              {sectionHome ? (
                <div className="center">
                  <h1 className="section-title">{sectionHome.titulo}</h1>
                  <div className="section-parragraph">
                    <p className="description pg-home">
                      {sectionHome.descripcion1}
                    </p>
                  </div>
                  <div className="section-icon-home">
                    <img src={home}></img>
                  </div>
                  <div className="section-button">
                    <button onClick={(e) => redirect(constant.HOMEHEADER)}>
                      Ingresa aquí
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
            <div
              className="bussines"
              onClick={(e) => redirect(constant.BUSSINESHEADER)}
            >
              {sectionBussines ? (
                <div className="center">
                  <h1 className="section-title">{sectionBussines.titulo}</h1>
                  <div className="section-parragraph">
                    <p className="description">
                      {sectionBussines.descripcion1}
                    </p>
                  </div>
                  <div className="section-icon">
                    <img src={business}></img>
                  </div>
                  <div className="section-button">
                    <button onClick={(e) => redirect(constant.BUSSINESHEADER)}>
                      Ingresa aquí
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* <div className="floating">
                        <div className="medium" style={{cursor:"pointer"}}>
                            <img onClick={()=>redirectWapp()} src={medium}></img>
                        </div>
                    </div> */}
        </div>
      )}
    </React.Fragment>
  );
};

export default Home;
